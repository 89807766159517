import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
import ScheduleSettingsComponent from 'src/components/PropertyManager/Schedules/ScheduleSettings';

const ScheduleSettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState('scheduleSettings');
  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <DefaultLayout title="Schedule Settings">
      <Tabs indicatorColor="secondary" onChange={handleTabChange} textColor="secondary" value={selectedTab}>
        <Tab label="Schedule Settings" value="scheduleSettings" />
      </Tabs>

      <Box sx={{ mt: 3 }}>{selectedTab === 'scheduleSettings' && <ScheduleSettingsComponent />}</Box>
    </DefaultLayout>
  );
};
export default ScheduleSettingsPage;
