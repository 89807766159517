import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Box, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import SecurityPinModal from 'src/components/EPU/SecurityPin/CRUD/Edit';
import useCRUD from 'src/hooks/useCRUD';

const PinSettings = () => {
  const { t } = useTranslation('security');
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentPin, setCurrentPin] = useState('');
  const [loading, setLoading] = useState(false);
  const { accounts } = useMsal();
  const [sessionData, setSessionData] = useState(null);
  const [pinExists, setPinExists] = useState(false);

  useEffect(() => {
    const sessionData = accounts[0]?.idTokenClaims;
    setSessionData(sessionData);
  }, [accounts]);

  const customerId = sessionData?.sub;
  const { data, saveData, saveData: validatePin } = useCRUD(customerId ? `${process.env.REACT_APP_API_URL}/user/${customerId}/pin` : null);

  // Verificar si existe un PIN al cargar la vista
  useEffect(() => {
    if (data?.successInd) {
      setPinExists(true);
      setCurrentPin(data.pin);
    } else {
      setPinExists(false);
      setCurrentPin('');
    }
  }, [data]);

  const handleModalClose = () => setModalOpen(false);

  // Abrir modal para crear PIN
  const handleCreatePin = () => {
    setIsEditMode(false);
    setModalOpen(true);
  };

  // Abrir modal para editar PIN
  const handleEditPin = () => {
    if (!pinExists) {
      toast.warning(t('messages.noPinWarning'));
      return;
    }
    setIsEditMode(true);
    setModalOpen(true);
  };

  // Guardar o actualizar PIN
  const handleSavePin = async pinData => {
    if (!customerId) {
      toast.error(t('errors.noUserId'));
      return;
    }

    setLoading(true);
    try {
      let method = isEditMode ? 'PATCH' : 'POST';
      const url = `${process.env.REACT_APP_API_URL}/user/${customerId}/pin`;

      // Validar PIN actual si es una edición
      if (isEditMode) {
        const validateResponse = await validatePin(`${url}/validate`, 'POST', { pin: pinData.oldPin });

        if (!validateResponse?.successInd || !validateResponse.valid) {
          toast.error(t('errors.incorrectPin'));
          setLoading(false);
          return;
        }
      }

      // Crear o actualizar PIN
      const response = await saveData(url, method, { pin: pinData.newPin });

      if (!response?.successInd) {
        throw new Error(`Error al ${isEditMode ? 'actualizar' : 'crear'} el PIN`);
      }

      setCurrentPin(pinData.newPin);
      setPinExists(true);
      toast.success(t(`messages.${isEditMode ? 'pinUpdateSuccess' : 'pinCreateSuccess'}`));
    } catch (error) {
      console.error('Error guardando el PIN:', error);
      toast.error(t(`errors.${isEditMode ? 'pinUpdateError' : 'pinCreateError'}`));
    } finally {
      setLoading(false);
      handleModalClose();
    }
  };

  return (
    <>
      <Stack>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-start' }}>
          <Button color="primary" disabled={pinExists} onClick={handleCreatePin} variant="contained">
            {t('buttons.createPin')}
          </Button>
          <Button color="secondary" disabled={!pinExists} onClick={handleEditPin} variant="contained">
            {t('buttons.editPin')}
          </Button>
        </Box>
      </Stack>

      <SecurityPinModal
        currentPin={currentPin}
        handleClose={handleModalClose}
        handleSave={handleSavePin}
        isEditMode={isEditMode}
        loading={loading}
        open={modalOpen}
      />
    </>
  );
};

export default PinSettings;
