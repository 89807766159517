import { memo, useContext, useState } from 'react';
import { useTheme } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Sidebar, TopMenu } from 'src/components/Navigation';
import Spinner from 'src/components/Progress/Spinner';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';

const drawerWidth = 240;

function DefaultLayout({ children, title }) {
  const { acceptedTerms, licensesData, userType } = useContext(AuthorizationContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const theme = useTheme();

  const { t } = useTranslation('global');

  const isDarkTheme = theme.palette.mode === 'dark';
  const colorLink = isDarkTheme ? 'white' : '#282b4c';

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const renderContent = () => {
    if (userType === 'EPM' && acceptedTerms && !licensesData) {
      return licensesData === undefined ? <Spinner /> : <Typography>{t('couldNotGetLicenseInfo')}</Typography>;
    }

    return children;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ToastContainer
        autoClose={5000}
        closeOnClick
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-right"
        rtl={false}
      />
      <AppBar
        position="fixed"
        sx={{
          ml: { sm: `${drawerWidth}px` },
          width: { sm: `calc(100% - ${drawerWidth}px)` }
        }}
      >
        <Toolbar disableGutters>
          <IconButton aria-label="open drawer" color="inherit" edge="start" onClick={handleDrawerToggle} sx={{ display: { sm: 'none' }, mr: 2 }}>
            <MenuIcon sx={{ color: colorLink }} />
          </IconButton>
          <TopMenu title={title} />
        </Toolbar>
      </AppBar>
      <Box aria-label="mailbox folders" component="nav" sx={{ flexShrink: { sm: 0 }, width: { sm: drawerWidth } }}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          onClose={handleDrawerClose}
          onTransitionEnd={handleDrawerTransitionEnd}
          open={mobileOpen}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            display: { sm: 'none', xs: 'block' }
          }}
          variant="temporary"
        >
          <Sidebar />;
        </Drawer>
        <Drawer
          open
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            display: { sm: 'block', xs: 'none' }
          }}
          variant="permanent"
        >
          <Sidebar />
        </Drawer>
      </Box>
      <Box component="main" minHeight="100vh" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        {/* Top spacer only */}
        <Toolbar />
        {renderContent()}
      </Box>
    </Box>
  );
}

export default memo(DefaultLayout);
