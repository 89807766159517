import React, { useEffect } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Molecules';
import * as Yup from 'yup';

const SecurityPinModal = ({ open, handleClose, handleSave, isEditMode }) => {
  const { t } = useTranslation('security');

  const validationSchema = Yup.object({
    confirmPin: Yup.string()
      .oneOf([Yup.ref('pin')], t('validation.pinMismatch'))
      .required(t('validation.confirmPinRequired')),
    oldPin: isEditMode
      ? Yup.string()
          .matches(/^\d{6}$/, t('validation.pinFormat'))
          .required(t('validation.currentPinRequired'))
      : Yup.string().notRequired(),
    pin: Yup.string()
      .matches(/^\d{6}$/, t('validation.pinFormat'))
      .required(t('validation.newPinRequired'))
  });

  const formik = useFormik({
    initialValues: { confirmPin: '', oldPin: '', pin: '' },
    onSubmit: values => {
      handleSave({
        newPin: values.pin,
        oldPin: values.oldPin
      });
    },
    validationSchema
  });

  useEffect(() => {
    if (open) formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal handleClose={handleClose} labels={{ title: isEditMode ? t('titles.editPin') : t('titles.createPin') }} open={open}>
      <Box component="form" onSubmit={formik.handleSubmit} sx={{ p: 3 }}>
        {isEditMode && (
          <TextField
            error={formik.touched.oldPin && Boolean(formik.errors.oldPin)}
            fullWidth
            helperText={formik.touched.oldPin && formik.errors.oldPin}
            inputProps={{ inputMode: 'numeric', maxLength: 6 }}
            label={t('labels.currentPin')}
            name="oldPin"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            sx={{ mb: 2 }}
            type="password"
            value={formik.values.oldPin}
          />
        )}
        <TextField
          error={formik.touched.pin && Boolean(formik.errors.pin)}
          fullWidth
          helperText={formik.touched.pin && formik.errors.pin}
          inputProps={{ inputMode: 'numeric', maxLength: 6 }}
          label={t('labels.newPin')}
          name="pin"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          sx={{ mb: 2 }}
          type="password"
          value={formik.values.pin}
        />
        <TextField
          error={formik.touched.confirmPin && Boolean(formik.errors.confirmPin)}
          fullWidth
          helperText={formik.touched.confirmPin && formik.errors.confirmPin}
          inputProps={{ inputMode: 'numeric', maxLength: 6 }}
          label={t('labels.confirmPin')}
          name="confirmPin"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          sx={{ mb: 2 }}
          type="password"
          value={formik.values.confirmPin}
        />
        <Button color="primary" disabled={!formik.isValid || !formik.dirty} sx={{ mt: 2 }} type="submit">
          {t('buttons.save')}
        </Button>
      </Box>
    </Modal>
  );
};

export default SecurityPinModal;
