import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';

const AccessPaymentRules = () => {
  const { t } = useTranslation('admin', { keyPrefix: 'paymentRules' });
  const { customerId } = useContext(AuthorizationContext);
  const [applyPaymentRules, setApplyPaymentRules] = useState(false);
  const { data, saveData } = useCRUD(customerId ? `${process.env.REACT_APP_API_URL}/property/${customerId}/payment-rules` : null);

  useEffect(() => {
    if (data && data.applyPaymentRules !== undefined) {
      setApplyPaymentRules(data.applyPaymentRules);
    }
  }, [data]);

  const handleToggle = () => {
    setApplyPaymentRules(prev => !prev);
  };

  const handleSave = async () => {
    if (!customerId) {
      toast.error(t('errors.noCustomerId'));
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/property/${customerId}/payment-rules`;
      const response = await saveData(url, 'POST', { applyPaymentRules });

      if (response?.successInd) {
        toast.success(t('messages.updateSuccess'));
      } else {
        throw new Error(response?.message || t('errors.updateError'));
      }
    } catch (error) {
      console.error('Error al guardar applyPaymentRules:', error);
      toast.error(t('errors.updateError'));
    }
  };

  return (
    <Box sx={{ maxWidth: 600, p: 3 }}>
      <FormControlLabel
        control={<Checkbox checked={applyPaymentRules} onChange={handleToggle} />}
        label={
          <Typography sx={{ fontWeight: 'bold' }} variant="body1">
            {t('labels.activatePaymentRules')}
          </Typography>
        }
      />
      <Typography sx={{ color: 'gray', mb: 2 }} variant="body2">
        {t('descriptions.activatePaymentRules')}
      </Typography>
      <Button color="primary" onClick={handleSave} sx={{ borderRadius: '5px', textTransform: 'none' }} variant="contained">
        {t('buttons.save')}
      </Button>
    </Box>
  );
};

export default AccessPaymentRules;
