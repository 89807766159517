/* eslint-disable sort-keys */
import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Molecules';

/**
 * Formatea los detalles del horario para mostrar los días de la semana en formato "Lun-Vie".
 * @param {Array} details - Lista de detalles del horario.
 * @returns {string} - Días agrupados en formato "Lun-Vie".
 */
const formatScheduleDays = details => {
  if (!details || details.length === 0) return 'Sin días';

  const daysMap = {
    Mon: 'Lun',
    Tue: 'Mar',
    Wed: 'Mié',
    Thu: 'Jue',
    Fri: 'Vie',
    Sat: 'Sáb',
    Sun: 'Dom'
  };

  const weekOrder = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const orderedDays = details.map(d => d.dayOfWeek).sort((a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b));

  let groupedDays = '';
  let start = orderedDays[0];
  let prev = orderedDays[0];

  for (let i = 1; i < orderedDays.length; i++) {
    if (weekOrder.indexOf(orderedDays[i]) !== weekOrder.indexOf(prev) + 1) {
      groupedDays += start === prev ? `${daysMap[start]}, ` : `${daysMap[start]}-${daysMap[prev]}, `;
      start = orderedDays[i];
    }
    prev = orderedDays[i];
  }
  groupedDays += start === prev ? daysMap[start] : `${daysMap[start]}-${daysMap[prev]}`;

  return groupedDays;
};

const ScheduleViewModal = ({ data, handleClose, open }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'schedules' });

  if (!data) return null;

  const { name, timezone, ScheduleDetails } = data;
  const startTime = ScheduleDetails?.[0]?.startTime || '-';
  const endTime = ScheduleDetails?.[0]?.endTime || '-';
  const formattedDays = formatScheduleDays(ScheduleDetails);

  return (
    <Modal handleClose={handleClose} labels={{ title: t('titles.scheduleDetails') }} minWidth={600} open={open}>
      <Box sx={{ p: 2 }}>
        <Stack spacing={1} sx={{ mt: 2 }}>
          <Typography>
            <strong>{t('labels.name')}:</strong> {name}
          </Typography>
          <Typography>
            <strong>{t('labels.timezone')}:</strong> {timezone}
          </Typography>
          <Typography>
            <strong>{t('labels.startTime')}:</strong> {startTime}
          </Typography>
          <Typography>
            <strong>{t('labels.endTime')}:</strong> {endTime}
          </Typography>
          <Typography>
            <strong>{t('labels.days')}:</strong> {formattedDays}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button color="primary" onClick={handleClose} sx={{ mt: 2 }}>
            {t('buttons.close')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ScheduleViewModal;
