import { useTranslation } from 'react-i18next';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
import { UsersCRUD } from 'src/components/PropertyManager/Users';

const UsersPage = () => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  return (
    <DefaultLayout title={t('title')}>
      <UsersCRUD userType={'EPU'} />
    </DefaultLayout>
  );
};

export default UsersPage;
