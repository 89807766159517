import { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';

import ScheduleEditModal from './Edit';
import ScheduleList from './List';
import ScheduleViewModal from './View';

const ScheduleSettings = () => {
  const { customerId } = useContext(AuthorizationContext);
  const { data, saveData } = useCRUD(`${process.env.REACT_APP_API_URL}/property/${customerId}/schedules`);
  const schedules = useMemo(() => data?.schedules || [], [data]);
  const [filteredSchedules, setFilteredSchedules] = useState(schedules);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const { t } = useTranslation('admin', { keyPrefix: 'schedules' });

  useEffect(() => {
    if (Array.isArray(schedules)) {
      setFilteredSchedules(schedules);
    } else {
      console.error('La API no devolvió un array de horarios:', schedules);
      setFilteredSchedules([]);
    }
  }, [schedules]);

  const handleModalClose = setModalOpen => {
    setSelectedSchedule(null);
    setModalOpen(false);
  };

  const handleModalOpen = (scheduleId, setModalOpen) => {
    if (scheduleId) {
      const foundSchedule = schedules.find(schedule => schedule.scheduleId === scheduleId);
      setSelectedSchedule(foundSchedule || null);
    } else {
      setSelectedSchedule(null);
    }
    setModalOpen(true);
  };

  const handleCreateModalOpen = () => handleModalOpen(null, setEditModalOpen);
  const handleEditModalClose = () => handleModalClose(setEditModalOpen);
  const handleEditModalOpen = scheduleId => handleModalOpen(scheduleId, setEditModalOpen);
  const handleViewModalClose = () => handleModalClose(setViewModalOpen);
  const handleViewModalOpen = scheduleId => handleModalOpen(scheduleId, setViewModalOpen);

  const handleSearch = e => {
    const value = e.target.value.toLowerCase();
    setQuery(value);

    setFilteredSchedules(value ? schedules.filter(schedule => schedule.name.toLowerCase().includes(value)) : schedules);
  };

  const handleScheduleSubmit = async schedule => {
    const { scheduleId } = schedule;
    const method = scheduleId ? 'PATCH' : 'POST';
    const url = scheduleId
      ? `${process.env.REACT_APP_API_URL}/property/${customerId}/schedule/${scheduleId}`
      : `${process.env.REACT_APP_API_URL}/property/${customerId}/schedule`;

    try {
      const response = await saveData(url, method, schedule);
      if (!response.successInd) {
        toast.error(t(`messages.${scheduleId ? 'scheduleUpdateError' : 'scheduleCreateError'}`));
        return;
      }
      toast.success(t(`messages.${scheduleId ? 'scheduleUpdateSuccess' : 'scheduleCreateSuccess'}`));
    } catch (error) {
      console.log(error);
      toast.error(t(`messages.${scheduleId ? 'scheduleUpdateError' : 'scheduleCreateError'}`));
    }
  };

  return (
    <>
      <Stack>
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <Button color="primary" onClick={handleCreateModalOpen} variant="contained">
            {t('buttons.create')}
          </Button>
          <TextField
            onChange={handleSearch}
            placeholder={t('placeholders.search')}
            size="small"
            sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, maxWidth: '300px' }}
            value={query}
            variant="outlined"
          />
        </Box>
        <ScheduleList handleEditModalOpen={handleEditModalOpen} handleViewModalOpen={handleViewModalOpen} schedules={filteredSchedules} />
      </Stack>
      <ScheduleEditModal data={selectedSchedule} handleClose={handleEditModalClose} handleSave={handleScheduleSubmit} open={editModalOpen} />
      <ScheduleViewModal data={selectedSchedule} handleClose={handleViewModalClose} open={viewModalOpen} />
    </>
  );
};

export default ScheduleSettings;
