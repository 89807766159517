import { Button, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const GeneralInformationView = ({ data, handleClose }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const { email = '', details = [], firstName = '', lastName = '' } = data || {};

  const parentUserEmail = details?.[0]?.parentUserDetails?.email;

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2}>
        <TextField disabled fullWidth label={t('fields.firstName')} value={firstName} />
        <TextField disabled fullWidth label={t('fields.lastName')} value={lastName} />
      </Stack>
      <TextField disabled fullWidth label={t('fields.email')} value={email} />
      {parentUserEmail && (
        <Typography color="textSecondary" variant="body2">
          {t('parentAccountInfo', { parentAccount: parentUserEmail })}
        </Typography>
      )}
      <Button color="primary" onClick={handleClose} sx={{ mt: 2 }} variant="contained">
        {t('close')}
      </Button>
    </Stack>
  );
};
