import { useContext, useEffect, useState } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';

import BridgeEditModal from './Edit';
import BridgesList from './List';
import BridgeViewModal from './View';

const BridgesCRUD = () => {
  const { customerId } = useContext(AuthorizationContext);
  const { data, saveData } = useCRUD(`${process.env.REACT_APP_API_URL}/property/${customerId}/bridges`);

  const [bridges, setBridges] = useState([]); // Se inicializa como un array vacío
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [filteredBridges, setFilteredBridges] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedBridge, setSelectedBridge] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(false);

  const { t } = useTranslation('admin', { keyPrefix: 'bridges' });

  useEffect(() => {
    if (data && Array.isArray(data)) {
      console.log('data:', data);

      setBridges(data);
      setFilteredBridges(data);
    }
  }, [data]);

  const handleEditModalOpen = bridgeId => {
    const bridge = bridges.find(b => b.bridge_id === bridgeId);
    if (!bridge) {
      console.error('Error: No se encontró el bridge con ID', bridgeId);
      return;
    }
    setSelectedBridge(bridge);
    setEditModalOpen(true);
  };

  const handleViewModalOpen = bridgeId => {
    console.log('Intentando abrir modal con bridge_id:', bridgeId); // Para depuración

    const bridge = bridges.find(b => b.bridge_id === bridgeId);

    if (!bridge) {
      console.error('Bridge no encontrado');
      return;
    }

    setSelectedBridge(bridge);
    setViewModalOpen(true);

    console.log('selectedBridge actualizado:', bridge); // Para verificar
  };

  const handleModalClose = setModalOpen => {
    setSelectedBridge(null);
    setModalOpen(false);
  };

  const handleEditModalClose = () => handleModalClose(setEditModalOpen);
  const handleViewModalClose = () => handleModalClose(setViewModalOpen);

  const handleSubmit = async bridge => {
    if (!bridge.bridge_id) {
      console.error('Error: bridge_id no está definido en handleSubmit');
      toast.error('Error: No se encontró el ID del puente.');
      return;
    }

    try {
      const method = 'PATCH';
      const url = `${process.env.REACT_APP_API_URL}/property/${customerId}/bridges/${bridge.bridge_id}`;

      // Filtrar solo puertas activas
      let doors = bridge.doors.map((door, index) => ({
        active: door.active,
        gateId: door.gateId && door.gateId !== '' ? door.gateId : undefined,
        name: door.name?.trim() !== '' ? door.name : 'Puerta sin nombre',
        openingTime: door.openingTime !== '' ? Number(door.openingTime) : 5,
        relay: `r${index + 1}`,
        type: door.type?.trim() !== '' ? door.type : 'S'
      }));

      //Si no hay puertas activas, evitar enviar `doors`
      const body = {
        antiPassback: bridge.antiPassback || false,
        doors,
        name: bridge.name
      };

      console.log('body:', JSON.stringify(body, null, 2));

      await saveData(url, method, body);
      toast.success(t('updateBridgeSuccess'));
    } catch (error) {
      console.error('Error al actualizar bridge:', error);
      toast.error(t('updateBridgeError'));
    }
  };

  const handleSearch = e => {
    const value = e.target.value.toLowerCase();
    setQuery(value);

    setFilteredBridges(
      value ? bridges.filter(bridge => bridge.name.toLowerCase().includes(value) || bridge.serial_number?.toLowerCase().includes(value)) : bridges
    );
  };

  return (
    <>
      <Stack>
        <Box style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          <TextField
            onChange={handleSearch}
            placeholder={t('search')}
            size="small"
            sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' }, maxWidth: '300px' }}
            value={query}
            variant="outlined"
          />
        </Box>
        <BridgesList bridges={filteredBridges} handleEditModalOpen={handleEditModalOpen} handleViewModalOpen={handleViewModalOpen} />
      </Stack>
      <BridgeEditModal data={selectedBridge} handleClose={handleEditModalClose} handleSave={handleSubmit} open={editModalOpen} />
      <BridgeViewModal data={selectedBridge} handleClose={handleViewModalClose} open={viewModalOpen} />
    </>
  );
};

export default BridgesCRUD;
