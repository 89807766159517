import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';

const GenericModal = ({ children, handleClose, labels, minHeight, minWidth, open }) => {
  const { title } = labels;

  return (
    <Modal onClose={handleClose} open={open}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          left: '50%',
          minHeight: minHeight,
          minWidth: minWidth || 400,
          p: 4,
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: theme => theme.palette.grey[500],
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box mt={title ? 0 : 2}>
          {title && (
            <Typography mb={2} variant="h4">
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </Box>
    </Modal>
  );
};

export default GenericModal;
