import { useCallback, useContext, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { b2cPolicies } from 'src/authConfig';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import { isBusinessPartnerStatusPending } from 'src/lib/util/authorization';
import { getBadgeImageFromGroups } from 'src/lib/util/navigation';

import { isNonProdEnv } from '../../lib/util/env-tools';
import DarkModeToggle from '../DarkModeToggle';

const TopMenu = ({ title }) => {
  const { logoUrl } = useContext(AuthorizationContext);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === 'dark';
  const isScreenSizeExtraSmall = useMediaQuery(theme.breakpoints.down(380));
  const { i18n, t } = useTranslation('nav');
  const locale = i18n.resolvedLanguage;
  const navigate = useNavigate();
  const settings = [
    { label: `${t('profile')}`, path: '/profile', visible: true },
    { label: `${t('changePassword')}`, path: '', visible: true },
    { label: `${t('logout')}`, path: '', visible: true }
  ];

  if (isBusinessPartnerStatusPending(accounts)) {
    settings[0].visible = false;
  }

  // Extraemos los grupos del token
  const tokenGroups = accounts?.[0]?.idTokenClaims?.groups;
  const userDefaultGroup = tokenGroups?.[0]?.split('|')?.[1];
  const userFirstName = accounts?.[0]?.idTokenClaims?.given_name;

  // Obtenemos la imagen del badge según los grupos del usuario

  const badgeImage = getBadgeImageFromGroups(tokenGroups || []) || logoUrl;

  const handleOpenUserMenu = useCallback(event => {
    setAnchorElUser(event.currentTarget);
  }, []);

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const handleMenuOptionClick = setting => {
    handleCloseUserMenu();

    if (setting.label === t('logout')) {
      localStorage.clear();
      // Implementamos el logout con MSAL usando redirect
      instance.logoutRedirect({
        postLogoutRedirectUri: 'https://entrypay.net'
      });
    } else if (setting.label === t('changePassword')) {
      instance.loginRedirect({
        authority: b2cPolicies.authorities.changePassword.authority,
        extraQueryParameters: {
          ui_locales: locale
        },
        redirectUri: '/',
        scopes: ['openid', 'profile']
      });
    } else {
      navigate(setting.path, { locale }); // Para las otras rutas
    }
  };

  return (
    <Container maxWidth="xl" sx={{ py: 1 }}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Box>
          <Typography color={isDarkTheme ? 'white' : 'black'} component="h1" variant="h4">
            {t(title)}
          </Typography>
        </Box>
        <Box sx={{ alignItems: 'center', display: 'flex', flexGrow: 0, gap: 2 }}>
          <Stack direction={isScreenSizeExtraSmall ? 'row-reverse' : 'row'} gap={2}>
            {isNonProdEnv && <DarkModeToggle />}
          </Stack>
          {isAuthenticated && (
            <Tooltip title={`${userFirstName ? userFirstName + ' | ' : ''}${userDefaultGroup}`}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="User Badge" src={badgeImage} />
              </IconButton>
            </Tooltip>
          )}
          <Menu
            anchorEl={anchorElUser}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top'
            }}
            id="menu-appbar"
            keepMounted
            onClose={handleCloseUserMenu}
            open={Boolean(anchorElUser)}
            sx={{ mt: '45px' }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top'
            }}
          >
            {settings.map(setting =>
              setting.visible ? (
                <MenuItem key={setting.label} onClick={() => handleMenuOptionClick(setting)}>
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ) : null
            )}
          </Menu>
        </Box>
      </Stack>
    </Container>
  );
};

export default TopMenu;
