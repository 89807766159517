import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BridgesList = ({ bridges, handleEditModalOpen, handleViewModalOpen }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'bridges' });

  if (!bridges || bridges.length === 0) {
    return <p style={{ padding: '20px', textAlign: 'center' }}>No hay bridges disponibles.</p>;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('name')}</TableCell>
            <TableCell>{t('serial_number')}</TableCell>
            <TableCell>{t('created_at')}</TableCell>
            <TableCell>{t('updated_at')}</TableCell>
            <TableCell>{t('actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bridges.map(bridge => (
            <TableRow key={bridge.bridge_id}>
              <TableCell>{bridge.name}</TableCell>
              <TableCell>{bridge.serial_number}</TableCell>
              <TableCell>{new Date(bridge.created_at).toLocaleString()}</TableCell>
              <TableCell>{bridge.updated_at ? new Date(bridge.updated_at).toLocaleString() : t('no_doors')}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleViewModalOpen(bridge.bridge_id)}>
                  <VisibilityIcon titleAccess={t('view')} />
                </IconButton>
                <IconButton onClick={() => handleEditModalOpen(bridge.bridge_id)}>
                  <EditIcon titleAccess={t('edit')} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BridgesList;
