import { Button, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Molecules';

const AccessPointViewModal = ({ data, handleClose, open }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'accessPoints' });

  const labels = { title: t('detailsAccessPoint') };
  const { name = '', description = '', bridges = [] } = data || {};

  const bridgesNames = bridges.map(bridge => bridge.name).join(', ');

  return (
    <Modal handleClose={handleClose} labels={labels} open={open}>
      <Stack gap={2}>
        <TextField disabled fullWidth label={t('fields.name')} value={name} />
        <TextField disabled fullWidth label={t('fields.description')} value={description} />
        <TextField disabled fullWidth label={t('fields.bridgesAssigned')} value={bridgesNames} />
        <Button color="primary" onClick={handleClose} variant="contained">
          {t('close')}
        </Button>
      </Stack>
    </Modal>
  );
};

export default AccessPointViewModal;
