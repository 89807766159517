import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { getUserType, hasPropertyManagerAcceptedTerms } from 'src/lib/util/authorization';

export const AuthorizationContext = createContext();

export const AuthorizationProvider = ({ children }) => {
  const { accounts } = useMsal();
  const userType = getUserType(accounts);

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [licensesData, setLicensesData] = useState();

  const localLicensesData = localStorage.getItem('localLicensesData') && JSON.parse(localStorage.getItem('localLicensesData'));

  const getLicensesData = useCallback(async () => {
    setIsLoading(true);
    try {
      if (localLicensesData) {
        setLicensesData(localLicensesData);
        setCustomerId(localLicensesData?.[0]?.customerId);
        setLogoUrl(localLicensesData?.[0]?.logoUrl);
      } else {
        // const userInfoResponse = await axios.get(`${process.env.REACT_APP_API_URL}/user/${accounts[0].idTokenClaims.sub}`);
        const licensesInfoResponse = await axios.get(`${process.env.REACT_APP_API_URL}/sales/license?userId=${accounts[0].idTokenClaims.sub}`);
        setLicensesData(licensesInfoResponse.data);
        setCustomerId(licensesInfoResponse.data?.[0]?.customerId);
        setLogoUrl(licensesInfoResponse.data?.[0]?.logoUrl);
        localStorage.setItem('localLicensesData', JSON.stringify(licensesInfoResponse.data));
      }
    } catch (error) {
      // To do: change API to send empty array instead of 404
      if (error?.response?.status === 404) {
        setLicensesData([]);
        localStorage.setItem('localLicensesData', []);
      } else {
        setLicensesData(null);
      }
      console.log('error getting license data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [accounts, localLicensesData]);

  if (userType === 'EPM' && !acceptedTerms && hasPropertyManagerAcceptedTerms(accounts)) {
    setAcceptedTerms(true);
  }

  useEffect(() => {
    if (userType === 'EPM' && hasPropertyManagerAcceptedTerms(accounts) && !isLoading && !licensesData) {
      licensesData === undefined && getLicensesData();
    }
  }, [accounts, getLicensesData, isLoading, licensesData, userType]);

  return (
    <AuthorizationContext.Provider
      value={{ acceptedTerms, customerId, licensesData, logoUrl, setAcceptedTerms, setCustomerId, setLicensesData, userType }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};
