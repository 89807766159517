import { useTranslation } from 'react-i18next';
import { Table } from 'src/components/Molecules';

const AccessPointsList = ({ accessPoints, bridges, handleEditModalOpen, handleViewModalOpen }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'accessPoints' });
  console.log('accessPoints', accessPoints);

  const columns = [
    { field: 'name', text: t('fields.name') },
    { field: 'bridges', text: t('fields.bridgesAssigned') },
    { field: 'createdAt', text: t('fields.createdAt') },
    { field: 'updatedAt', text: t('fields.updatedAt') }
  ];

  const rows = accessPoints.map(point => {
    // Filtrar los bridges que corresponden a este access point
    const assignedBridges = bridges.filter(bridge => bridge.access_point_id === point.accessPointId);

    return {
      bridges: assignedBridges.length, // Mostrar la cantidad de bridges asignados
      createdAt: point.createdAt ? new Date(point.createdAt).toLocaleString() : '-',
      id: point.accessPointId,
      name: point.name,
      updatedAt: point.updatedAt ? new Date(point.updatedAt).toLocaleString() : '-'
    };
  });

  return <Table columns={columns} loading={!accessPoints} onEdit={handleEditModalOpen} onView={handleViewModalOpen} rows={rows} />;
};

export default AccessPointsList;
