/* eslint-disable sort-keys */
import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, MenuItem, Select, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Molecules';

const initialScheduleState = {
  endTime: '18:00',
  name: '',
  selectedDays: [],
  startTime: '09:00',
  timezone: 'America/Mexico_City'
};

const ScheduleEditModal = ({ open, handleClose, data: scheduleData, handleSave }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'schedules' });
  const [schedule, setSchedule] = useState(initialScheduleState);

  const daysOfWeek = [
    { label: t('days.mon'), value: 'Mon' },
    { label: t('days.tue'), value: 'Tue' },
    { label: t('days.wed'), value: 'Wed' },
    { label: t('days.thu'), value: 'Thu' },
    { label: t('days.fri'), value: 'Fri' },
    { label: t('days.sat'), value: 'Sat' },
    { label: t('days.sun'), value: 'Sun' }
  ];

  useEffect(() => {
    if (open) {
      if (scheduleData) {
        setSchedule({
          scheduleId: scheduleData.scheduleId || null,
          name: scheduleData.name || '',
          timezone: scheduleData.timezone || 'America/Mexico_City',
          startTime: scheduleData.ScheduleDetails?.[0]?.startTime || '09:00',
          endTime: scheduleData.ScheduleDetails?.[0]?.endTime || '18:00',
          selectedDays: scheduleData.ScheduleDetails?.map(detail => detail.dayOfWeek) || []
        });
      } else {
        setSchedule(initialScheduleState); // Reset cuando es un nuevo horario
      }
    }
  }, [scheduleData, open]);

  const handleInputChange = (field, value) => {
    setSchedule(prev => ({ ...prev, [field]: value }));
  };

  const handleDayToggle = day => {
    setSchedule(prev => ({
      ...prev,
      selectedDays: prev.selectedDays.includes(day)
        ? prev.selectedDays.filter(d => d !== day) // Remueve si ya está seleccionado
        : [...prev.selectedDays, day] // Agrega si no está
    }));
  };

  const handleSubmit = () => {
    const formattedSchedule = {
      scheduleId: schedule.scheduleId || null,
      name: schedule.name,
      timezone: schedule.timezone,
      details: schedule.selectedDays.map(day => ({
        dayOfWeek: day,
        startTime: schedule.startTime,
        endTime: schedule.endTime
      }))
    };

    console.log('📤 Enviando al backend:', formattedSchedule);
    handleSave(formattedSchedule);
    handleClose();
  };

  return (
    <Modal handleClose={handleClose} labels={{ title: scheduleData ? t('titles.editSchedule') : t('titles.newSchedule') }} minWidth={700} open={open}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField fullWidth label={t('labels.name')} onChange={e => handleInputChange('name', e.target.value)} value={schedule.name} />
          </Grid>
          <Grid item xs={12}>
            <Select fullWidth onChange={e => handleInputChange('timezone', e.target.value)} value={schedule.timezone}>
              <MenuItem value="America/Mexico_City">{t('timezones.mexicoCity')}</MenuItem>
              <MenuItem value="America/Guadalajara">{t('timezones.guadalajara')}</MenuItem>
              <MenuItem value="America/Monterrey">{t('timezones.monterrey')}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={t('labels.startTime')}
              onChange={e => handleInputChange('startTime', e.target.value)}
              type="time"
              value={schedule.startTime}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={t('labels.endTime')}
              onChange={e => handleInputChange('endTime', e.target.value)}
              type="time"
              value={schedule.endTime}
            />
          </Grid>
        </Grid>

        {/* Días de la semana */}
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', mt: 2 }}>
          {daysOfWeek.map(day => (
            <FormControlLabel
              control={<Checkbox checked={schedule.selectedDays.includes(day.value)} onChange={() => handleDayToggle(day.value)} />}
              key={day.value}
              label={day.label}
            />
          ))}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button color="primary" onClick={handleSubmit} sx={{ mr: 1 }}>
            {t('buttons.save')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ScheduleEditModal;
