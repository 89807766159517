// Teporarily hardcode group ids
export const EP_USR = [
  { id: 'bd5fc24b-8022-4b75-b970-db6192695ac9', key: 'SUE' },
  { id: '227a1af8-ead1-4db1-9ab9-746986a331d2', key: 'SUA' },
  { id: 'b3ccfd25-f7b2-412a-a74e-b2a102a12647', key: 'CME' },
  { id: '688fdacd-3a00-4bb0-bef6-957d339f4346', key: 'REE' },
  { id: '31523a85-135c-44ea-a29a-7adf6605b90b', key: 'BPE' },
  { id: '70d16a4e-f22a-4310-ae49-8f2d40ec8f22', key: 'TSE' },
  { id: '677116ec-4a00-455d-8dd5-2f79a0cd6748', key: 'EPM' }, // Property Management
  { id: 'fc9f01a4-3ea8-4a77-8ffa-4d64a02596e8', key: 'DUE' } //EPU
];

export const MENU_OPTIONS = {
  BPE: [
    {
      label: 'reports',
      url: '/reports'
    },
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  CME: [
    {
      label: 'globalConfig',
      url: '/global-config'
    },
    {
      label: 'commerceManagement',
      //SalesTracking
      subPages: [
        {
          label: 'salesTracking',
          url: '/commerce-management/sales-tracking'
        }
      ],
      url: '/commerce-management'
    },
    {
      label: 'inventoryManagement',
      subPages: [
        {
          label: 'bridges',
          url: '/inventory-management/bridges'
        }
      ],
      url: '/inventory-management'
    },
    {
      label: 'reports',
      url: '/reports'
    },
    {
      label: 'integrationManagement',
      url: '/integrations'
    },
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  DUE: [
    {
      label: 'profile',
      url: '/profile'
    },
    {
      label: 'securityPin',
      url: '/settings/pins'
    }
  ],
  EPM: [
    {
      label: 'profile',
      url: '/profile'
    },
    {
      label: 'configuration',
      //SalesTracking
      subPages: [
        {
          label: 'general',
          url: '/property-management/settings/general'
        },
        {
          label: 'georeferences',
          url: '/property-management/settings/georeferences'
        },
        {
          label: 'payments',
          url: '/property-management/settings/payments'
        },
        {
          label: 'users',
          url: '/property-management/settings/users'
        },
        {
          label: 'accessPoints',
          url: '/property-management/settings/access-points'
        },
        {
          label: 'schedules',
          url: '/property-management/settings/schedules'
        }
      ],
      url: '/property-management'
    },
    {
      label: 'users',
      subPages: [
        {
          label: 'users',
          url: '/property-management/users/users'
        },
        {
          label: 'employees',
          url: '/property-management/users/employees'
        },
        {
          label: 'guards',
          url: '/property-management/users/guards'
        }
      ],
      url: '/property-management/users' // To do: remove when not necessary (used in Sidebar component as key)
    }
  ],
  REE: [
    {
      label: 'globalConfig',
      url: '/global-config'
    },
    {
      label: 'commerceManagement',
      //SalesTracking
      subPages: [
        {
          label: 'salesTracking',
          url: '/commerce-management/sales-tracking'
        }
      ],
      url: '/commerce-management'
    },
    {
      label: 'inventoryManagement',
      subPages: [
        {
          label: 'bridges',
          url: '/inventory-management/bridges'
        }
      ],
      url: '/inventory-management'
    },
    {
      label: 'reports',
      url: '/reports'
    },
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  SUA: [
    {
      label: 'globalConfig',
      url: '/global-config'
    },
    {
      label: 'commerceManagement',
      //SalesTracking
      subPages: [
        {
          label: 'salesTracking',
          url: '/commerce-management/sales-tracking'
        }
      ],
      url: '/commerce-management'
    },
    {
      label: 'inventoryManagement',
      subPages: [
        {
          label: 'bridges',
          url: '/inventory-management/bridges'
        }
      ],
      url: '/inventory-management'
    },
    {
      label: 'reports',
      url: '/reports'
    },
    {
      label: 'integrationManagement',
      url: '/integrations'
    },
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  SUE: [
    {
      label: 'globalConfig',
      url: '/global-config'
    },
    {
      label: 'commerceManagement',
      //SalesTracking
      subPages: [
        {
          label: 'salesTracking',
          url: '/commerce-management/sales-tracking'
        }
      ],
      url: '/commerce-management'
    },
    {
      label: 'inventoryManagement',
      subPages: [
        {
          label: 'bridges',
          url: '/inventory-management/bridges'
        }
      ],
      url: '/inventory-management'
    },
    {
      label: 'reports',
      url: '/reports'
    },
    {
      label: 'integrationManagement',
      url: '/integrations'
    },
    {
      label: 'profile',
      url: '/profile'
    }
  ],
  TSE: [
    {
      label: 'profile',
      url: '/profile'
    }
  ]
};
