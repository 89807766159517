import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
import { UsersSettings } from 'src/components/PropertyManager/Users';
import AccessPaymentRules from 'src/components/PropertyManager/Users/Payments';

const SettingsUsers = () => {
  const [selectedTab, setSelectedTab] = useState('welcomeLetter');
  const { t } = useTranslation('admin', { keyPrefix: 'users' });

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <DefaultLayout title={t('title')}>
      <Tabs indicatorColor="secondary" onChange={handleTabChange} textColor="secondary" value={selectedTab}>
        <Tab label={t('welcomeLetter')} value="welcomeLetter" />
        <Tab label={t('accessPaymentRules')} value="paymentRules" />
      </Tabs>

      <Box sx={{ mt: 3 }}>
        {selectedTab === 'welcomeLetter' && <UsersSettings />}
        {selectedTab === 'paymentRules' && <AccessPaymentRules />}
      </Box>
    </DefaultLayout>
  );
};

export default SettingsUsers;
