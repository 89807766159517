import { useContext, useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';

import UserEditModal from './Edit';
import UsersList from './List';
import UserViewModal from './View';

const UsersCRUD = ({ userType }) => {
  const { customerId } = useContext(AuthorizationContext);
  const { data: users, saveData } = useCRUD(`${process.env.REACT_APP_API_URL}/property/${customerId}/users?userType=${userType}`);
  const [filteredUsers, setFilteredUsers] = useState(users || []);
  const [isAccountHolder, setIsAccountHolder] = useState(true);
  const [loading, setLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);

  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  useEffect(() => {
    if (users) {
      setFilteredUsers(users.filter(user => (isAccountHolder ? !user.details?.[0]?.parentUserId : user.details?.[0]?.parentUserId)));
    }
  }, [isAccountHolder, users]);

  const inviteButtonLabel = {
    EPE: 'inviteEmployee',
    EPG: 'inviteGuard',
    EPU: 'inviteUser'
  };

  const handleModalClose = setModalOpen => {
    setSelectedUser(null);
    setModalOpen(false);
  };

  const handleModalOpen = (userId, setModalOpen) => {
    if (userId) setSelectedUser(users.find(user => user.userId === userId));
    setModalOpen(true);
  };

  const handleEditModalClose = () => handleModalClose(setEditModalOpen);
  const handleEditModalOpen = userId => handleModalOpen(userId, setEditModalOpen);

  const handleViewModalClose = () => handleModalClose(setViewModalOpen);
  const handleViewModalOpen = userId => handleModalOpen(userId, setViewModalOpen);

  const handleSearch = e => {
    const value = e.target.value.toLowerCase();
    setQuery(value);

    setFilteredUsers(
      value
        ? users.filter(
            user =>
              (user.firstName.toLowerCase().includes(value) ||
                user.lastName?.toLowerCase().includes(value) ||
                user.email?.toLowerCase().includes(value)) &&
              (isAccountHolder ? !user.details?.[0]?.parentUserId : user.details?.[0]?.parentUserId)
          )
        : users.filter(user => (isAccountHolder ? !user.details?.[0]?.parentUserId : user.details?.[0]?.parentUserId))
    );
  };

  const handleCheckboxChange = event => {
    setIsAccountHolder(event.target.checked);
  };

  const handleUserSubmit = async user => {
    const { userId } = user;
    const method = userId ? 'PATCH' : 'POST';
    const url = userId
      ? `${process.env.REACT_APP_API_URL}/property/${customerId}/user/${userId}`
      : `${process.env.REACT_APP_API_URL}/property/${customerId}/user`;

    try {
      setLoading(true);
      const data = await saveData(url, method, { ...user, userType });
      if (!data.successInd) {
        toast.error(t(`messages.${userId ? 'userUpdateError' : 'userAlreadyExists'}`));
        return;
      }
      toast.success(t(`messages.${userId ? 'userUpdateSuccess' : 'userCreateSuccess'}`));
    } catch (error) {
      console.log(error);
      toast.error(t(`messages.${userId ? 'userUpdateError' : 'userCreateError'}`));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack>
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <Button color="primary" onClick={handleEditModalOpen} variant="contained">
            {t(inviteButtonLabel[userType])}
          </Button>
          <Stack direction="row">
            {userType === 'EPU' && (
              <FormControlLabel control={<Checkbox checked={isAccountHolder} onChange={handleCheckboxChange} />} label="Titular" />
            )}
            <TextField
              onChange={handleSearch}
              placeholder={t('placeholders.search')}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                },
                maxWidth: '300px'
              }}
              value={query}
              variant="outlined"
            />
          </Stack>
        </Box>
        <UsersList handleEditModalOpen={handleEditModalOpen} handleViewModalOpen={handleViewModalOpen} users={filteredUsers} />
      </Stack>
      <UserEditModal action={handleUserSubmit} data={selectedUser} handleClose={handleEditModalClose} loading={loading} open={editModalOpen} />
      <UserViewModal data={selectedUser} handleClose={handleViewModalClose} open={viewModalOpen} />
    </>
  );
};

export default UsersCRUD;
