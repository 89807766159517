import { EP_USR } from 'src/lib/constants';

export const getUserType = accounts => {
  const [id] = accounts?.[0]?.idTokenClaims?.groups?.[0]?.split('|') ?? [];
  return EP_USR.find(g => g.id === id)?.key;
};

export const hasPropertyManagerAcceptedTerms = accounts => {
  // Read from localStorage
  let acceptedTerms = localStorage.getItem('acceptedTerms');
  // If the item does not exist, set it
  if (!acceptedTerms || acceptedTerms === 'undefined') {
    const epmUserGroup = EP_USR.find(g => g.key === 'EPM');
    const isPropertyManager = accounts?.[0]?.idTokenClaims?.groups?.length === 1 && accounts[0].idTokenClaims?.groups?.[0]?.includes(epmUserGroup.id);
    acceptedTerms = accounts?.[0]?.idTokenClaims?.acceptedTerms && isPropertyManager;
    localStorage.setItem('acceptedTerms', acceptedTerms);
  }

  return acceptedTerms === 'true' || acceptedTerms === true;
};

export const isBusinessPartnerStatusPending = accounts => {
  const bpeUserGroup = EP_USR.find(g => g.key === 'BPE');
  const isBusinessPartner = accounts?.[0]?.idTokenClaims?.groups?.length === 1 && accounts[0].idTokenClaims?.groups?.[0]?.includes(bpeUserGroup.id);
  return accounts?.[0]?.idTokenClaims?.verificationStatus === 'pending' && isBusinessPartner;
};
