import Bridge from './bridge';
import BusinessClub from './business-club';
import SalesTracking from './commerce-management/sales-tracking';
import Dashboard from './dashboard';
import SecurityPinPage from './EPU/pins';
import GlobalConfig from './global-config';
import NotFound from './not-found';
import Profile from './profile';
import AccessPointsPage from './property-management/settings/access-points';
import Configuration from './property-management/settings/general/';
import GeoReferences from './property-management/settings/georeferences';
import PaymentsSettingsPage from './property-management/settings/payments';
import ScheduleSettingsPage from './property-management/settings/schedules';
import UsersSettings from './property-management/settings/users';
import UsersPage from './property-management/users';
import EmployeesPage from './property-management/users/employees';
import GuardsPage from './property-management/users/guards';

const pagesData = [
  {
    alwaysAvailable: true,
    element: <Dashboard />,
    path: '/',
    title: 'home'
  },
  {
    element: <>About</>,
    path: '/about',
    title: 'about'
  },
  {
    element: <BusinessClub />,
    path: '/business-club',
    title: 'businessClub'
  },
  {
    element: <GlobalConfig />,
    path: '/global-config',
    title: 'globalConfig'
  },
  {
    element: <NotFound />,
    path: '*',
    title: 'not-found'
  },
  {
    alwaysAvailable: true,
    element: <Profile />,
    path: '/profile',
    title: 'profile'
  },
  {
    element: <SalesTracking />,
    path: '/commerce-management/sales-tracking',
    title: 'salesTracking'
  },
  {
    element: <Bridge />,
    path: '/inventory-management/bridges',
    title: 'bridge'
  },
  {
    element: <Configuration />,
    path: '/property-management/settings/general',
    title: 'general'
  },
  {
    element: <PaymentsSettingsPage />,
    path: '/property-management/settings/payments',
    title: 'payments'
  },
  {
    element: <GeoReferences />,
    path: '/property-management/settings/georeferences',
    title: 'georeferences'
  },
  {
    element: <UsersSettings />,
    path: '/property-management/settings/users',
    title: 'users'
  },
  {
    element: <UsersPage />,
    path: '/property-management/users/users',
    title: 'users'
  },
  {
    element: <EmployeesPage />,
    path: '/property-management/users/employees',
    title: 'employees'
  },
  {
    element: <GuardsPage />,
    path: '/property-management/users/guards',
    title: 'guards'
  },
  {
    element: <AccessPointsPage />,
    path: '/property-management/settings/access-points',
    title: 'accessPoints'
  },
  {
    element: <ScheduleSettingsPage />,
    path: '/property-management/settings/schedules',
    title: 'schedules'
  },
  {
    element: <SecurityPinPage />,
    path: '/settings/pins',
    title: 'securityPin'
  }
];

export default pagesData;
