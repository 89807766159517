import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

const TabsComponent = ({ orientation, tabs }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const verticalProps = orientation === 'vertical' && {
    orientation: 'vertical'
  };

  if (tabs.length > 4) {
    verticalProps.variant = 'scrollable';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: orientation === 'vertical' ? 'row' : 'column'
      }}
    >
      <Tabs indicatorColor="secondary" onChange={handleTabChange} textColor="secondary" value={selectedTab} {...verticalProps}>
        {tabs.map(tab => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>

      <Box sx={{ ml: orientation === 'vertical' ? 3 : 0, mt: orientation === 'vertical' ? 0 : 3, width: '100%' }}>
        {tabs.map(tab => (
          <Box key={tab.value} sx={{ display: selectedTab === tab.value ? 'block' : 'none' }}>
            {tab.component}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TabsComponent;
