import { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Modal, Tabs } from 'src/components/Molecules';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';

import GeneralInformationEdit from './General';
import UserStatusEdit from './Status';
import UserSubscriptionsEdit from './Subscriptions';

const UserEditModal = ({ action, data, handleClose, loading, open }) => {
  const { customerId } = useContext(AuthorizationContext);
  const [user, setUser] = useState(data);

  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const labels = { title: t(data ? 'editUser' : 'inviteUser') };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const getUserResponse = await axios.get(`${process.env.REACT_APP_API_URL}/property/${customerId}/user/${data.userId}`);
        setUser(getUserResponse?.data?.user);
      } catch (error) {
        console.error('Error fetching user details', error);
      }
    };

    if (data) {
      fetchUser();
    }
  }, [customerId, data]);

  const handleModalClose = useCallback(() => {
    setUser(null);
    handleClose();
  }, [handleClose]);

  const getModalContent = useCallback(() => {
    if (data) {
      const tabs = [
        {
          component: <GeneralInformationEdit action={action} data={user} handleClose={handleModalClose} loading={loading} />,
          label: t('tabs.general'),
          value: 'general'
        }
      ];
      // Only available for EPU account holders
      if (!data?.details?.[0]?.parentUserId && data?.details?.[0]?.type === 'EPU') {
        tabs.push(
          { component: <UserSubscriptionsEdit loading={loading} />, label: t('tabs.subscriptions'), value: 'subscriptions' },
          {
            component: <GeneralInformationEdit action={action} handleClose={handleModalClose} loading={loading} parentUser={user} />,
            label: t('tabs.subaccounts'),
            value: t('subaccounts')
          }
        );
      }
      // Available to all users
      tabs.push({
        component: <UserStatusEdit action={action} data={user} handleClose={handleModalClose} loading={loading} />,
        label: t('tabs.status'),
        value: 'status'
      });
      return <Tabs orientation="vertical" tabs={tabs} />;
    }
    return <GeneralInformationEdit action={action} data={user} handleClose={handleModalClose} loading={loading} />;
  }, [action, data, handleModalClose, loading, t, user]);

  return (
    <Modal handleClose={handleModalClose} labels={labels} minHeight={400} minWidth={650} open={open}>
      {getModalContent()}
    </Modal>
  );
};

export default UserEditModal;
