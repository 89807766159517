import React, { useContext, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';

const UsersSettings = () => {
  const { t } = useTranslation('admin');
  const { customerId } = useContext(AuthorizationContext);
  const [content, setContent] = useState(t('users.welcomeLetter'));

  useEffect(() => {
    const fetchWelcomeLetterEPU = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/property/${customerId}/welcome-letter-epu`);

        if (response.data.customer?.welcomeLetterEpu) {
          setContent(response.data.customer.welcomeLetterEpu);
        }
      } catch (error) {
        console.error(t('users.errorSave'), error);
        toast.error(t('users.errorSave'), {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right'
        });
      }
    };

    fetchWelcomeLetterEPU();
  }, [customerId, t]);

  const handleEditorChange = newContent => {
    setContent(newContent);
  };

  const handleSave = async () => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/property/${customerId}/welcome-letter-epu`, {
        welcomeLetterEpu: content
      });

      toast.success(t('users.successSave'), {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right'
      });
      console.log('Respuesta del servidor:', response.data);
    } catch (error) {
      toast.error(t('users.errorSave'), {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right'
      });
      console.error(t('users.errorSave'), error);
    }
  };

  const handleSendTestEmail = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/property/${customerId}/welcome-letter-epu/send`, {
        sentBy: 'c1be8d6f-8787-4707-aa94-15c911e5a65e'
      });

      toast.success(t('users.successSendEmail'), {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right'
      });
      console.log('Respuesta del servidor:', response.data);
    } catch (error) {
      toast.error(t('users.errorSendEmail'), {
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right'
      });
      console.error(t('users.errorSendEmail'), error);
    }
  };

  return (
    <Box>
      <p>{t('users.welcomeMessage')}</p>

      <Editor
        init={{
          branding: false,
          content_css: 'default',
          height: 300,
          menubar: true,
          skin: 'oxide',
          toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | removeformat | help'
        }}
        onEditorChange={handleEditorChange}
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        value={content}
      />

      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button color="primary" onClick={handleSave} variant="contained">
          {t('users.saveButton')}
        </Button>
        <Button color="primary" onClick={handleSendTestEmail} variant="text">
          {t('users.sendTestEmailButton')}
        </Button>
      </Box>
    </Box>
  );
};

export default UsersSettings;
