import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Molecules';
import * as Yup from 'yup';

const AccessPointEditModal = ({ action, data, handleClose, open, bridges }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'accessPoints' });

  const labels = { title: t(data ? 'editAccessPoint' : 'addAccessPoint') };
  const { accessPointId, name = '', description = '', bridgesAssigned = [] } = data || {};

  const validationSchema = Yup.object().shape({
    bridgesAssigned: Yup.array().min(1, t('validations.bridgeRequired')),
    description: Yup.string().trim().required(t('validations.descriptionRequired')),
    name: Yup.string().trim().required(t('validations.nameRequired'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { bridgesAssigned: bridgesAssigned.map(bridge => bridge.bridge_id), description, name },
    onSubmit: async values => {
      const assignedBridges = values.bridgesAssigned.map(id => bridges.find(bridge => bridge.bridge_id === id));
      await action({ ...values, accessPointId, bridgesAssigned: assignedBridges });
      handleModalClose();
    },
    validationSchema
  });

  const handleModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal handleClose={handleModalClose} labels={labels} open={open}>
      <form onSubmit={formik.handleSubmit}>
        <Stack gap={2}>
          <TextField
            error={formik.touched.name && Boolean(formik.errors.name)}
            fullWidth
            helperText={formik.touched.name && formik.errors.name}
            label={t('fields.name')}
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          <TextField
            error={formik.touched.description && Boolean(formik.errors.description)}
            fullWidth
            helperText={formik.touched.description && formik.errors.description}
            label={t('fields.description')}
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
          />
          <FormControl fullWidth>
            <InputLabel>{t('fields.bridgesAssigned')}</InputLabel>
            <Select
              label={t('fields.bridgesAssigned')}
              multiple
              name="bridgesAssigned"
              onChange={formik.handleChange}
              renderValue={selected => selected.map(id => bridges.find(bridge => bridge.bridge_id === id)?.name).join(', ')}
              value={formik.values.bridgesAssigned}
            >
              {bridges.map(bridge => (
                <MenuItem key={bridge.bridge_id} value={bridge.bridge_id}>
                  {bridge.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{t('fields.selectMultipleBridges')}</FormHelperText>
          </FormControl>
          <Typography color="textSecondary" variant="body2">
            {t('noteWarning')}
          </Typography>
          <Button color="primary" disabled={!formik.isValid} type="submit" variant="contained">
            {t('save')}
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};

export default AccessPointEditModal;
