import { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Modal, Tabs } from 'src/components/Molecules';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';

import UsersList from '../List';
import { GeneralInformationView } from './General';
import UserStatusView from './Status';

const UserViewModal = ({ data, handleClose, open }) => {
  const { customerId } = useContext(AuthorizationContext);
  const [user, setUser] = useState(data);
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const labels = { title: t('userInfo') };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const getUserResponse = await axios.get(`${process.env.REACT_APP_API_URL}/property/${customerId}/user/${data.userId}`);
        setUser(getUserResponse?.data?.user);
      } catch (error) {
        console.error('Error fetching user details', error);
      }
    };

    if (data) {
      fetchUser();
    }
  }, [customerId, data]);

  const getModalContent = useCallback(() => {
    if (data) {
      const tabs = [
        {
          component: <GeneralInformationView data={user} handleClose={handleClose} />,
          label: t('tabs.general'),
          value: 'general'
        }
      ];
      // Only available for EPU account holders
      if (!data?.details?.[0]?.parentUserId && data?.details?.[0]?.type === 'EPU') {
        tabs.push({
          component: <UsersList users={user?.dependentUsers || []} />,
          label: t('tabs.subaccounts'),
          value: t('subaccounts')
        });
      }
      // Available to all users
      tabs.push({
        component: <UserStatusView data={user} handleClose={handleClose} />,
        label: t('tabs.status'),
        value: 'status'
      });
      return <Tabs orientation="vertical" tabs={tabs} />;
    }
  }, [data, handleClose, t, user]);

  return (
    <Modal handleClose={handleClose} labels={labels} minWidth={650} open={open}>
      {getModalContent()}
    </Modal>
  );
};

export default UserViewModal;
