import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SecurityPin from 'src/components/EPU/SecurityPin/CRUD/index';
import DefaultLayout from 'src/components/layouts/DefaultLayout';

const SecurityPinPage = () => {
  const [selectedTab, setSelectedTab] = useState('securityPin');
  const { t } = useTranslation('security');
  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <DefaultLayout title={t('titles.title')}>
      <Tabs indicatorColor="secondary" onChange={handleTabChange} textColor="secondary" value={selectedTab}>
        <Tab label={t('titles.title')} value="securityPin" />
      </Tabs>
      <Box sx={{ mt: 3 }}>{selectedTab === 'securityPin' && <SecurityPin />}</Box>
    </DefaultLayout>
  );
};
export default SecurityPinPage;
