import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BridgeViewModal = ({ data, handleClose, open }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'bridges' });

  if (!data) return null;

  const doors = data.doors || [];

  const getDoorType = type => {
    switch (type) {
      case 'E':
        return t('entrance');
      case 'S':
        return t('exit');
      case 'ES':
        return t('entrance_exit');
      default:
        return t('unknown');
    }
  };

  return (
    <Modal onClose={handleClose} open={open}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          left: '50%',
          maxHeight: '80vh',
          overflowY: 'auto',
          p: 4,
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500
        }}
      >
        <Typography sx={{ fontWeight: 'bold', mb: 2 }} variant="h6">
          {t('details')}
        </Typography>

        <Typography>
          <strong>{t('name')}:</strong> {data.name}
        </Typography>
        <Typography>
          <strong>{t('serial_number')}:</strong> {data.serial_number}
        </Typography>

        <Typography sx={{ fontWeight: 'bold', mt: 2 }}>{t('doors')}</Typography>
        {doors.length > 0 ? (
          <Box component="ul" sx={{ mt: 0, pl: 2 }}>
            {doors.map((door, index) => (
              <li key={door.gateId}>
                {index + 1}. {door.name} - {getDoorType(door.type)} - {door.openingTime} {t('seconds')} - {door.active ? t('active') : t('inactive')}
              </li>
            ))}
          </Box>
        ) : (
          <Typography sx={{ fontStyle: 'italic', mt: 1 }}>{t('no_doors')}</Typography>
        )}

        <Typography sx={{ mt: 2 }}>
          <strong>{t('anti_passback')}:</strong> {data.antiPassback ? t('enabled') : t('disabled')}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button onClick={handleClose} sx={{ color: 'orange', textTransform: 'none' }}>
            {t('close')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default BridgeViewModal;
