import { useTranslation } from 'react-i18next';
import { Table } from 'src/components/Molecules';

const UsersList = ({ handleEditModalOpen, handleViewModalOpen, users }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const columns = [
    { field: 'fullName', text: t('fields.fullName') },
    { field: 'email', text: t('fields.email') },
    { field: 'status', text: t('fields.status') }
  ];

  const rows = users.map(user => ({
    accountType: user.accountType,
    email: user.email,
    fullName: `${user.firstName} ${user.lastName}`,
    id: user.userId,
    status: t(user.details?.[0]?.status ? 'status.active' : 'status.inactive')
  }));

  return <Table columns={columns} loading={!users} onEdit={handleEditModalOpen} onView={handleViewModalOpen} rows={rows} />;
};

export default UsersList;
