import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const DEFAULT_DOORS = [
  { active: false, gateId: '', name: '', openingTime: '', relay: '', type: '' },
  { active: false, gateId: '', name: '', openingTime: '', relay: '', type: '' },
  { active: false, gateId: '', name: '', openingTime: '', relay: '', type: '' },
  { active: false, gateId: '', name: '', openingTime: '', relay: '', type: '' }
];

const BridgeEditModal = ({ data, handleClose, open, handleSave }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'bridges' });
  const validationSchema = Yup.object().shape({
    antiPassback: Yup.boolean(),
    doors: Yup.array().of(
      Yup.object().shape({
        active: Yup.boolean(),
        gateId: Yup.string(),
        name: Yup.string().when('active', {
          is: true,
          otherwise: schema => schema.notRequired(),
          then: schema => schema.required(t('name_required'))
        }),
        openingTime: Yup.number().when('active', {
          is: true,
          otherwise: schema => schema.notRequired(),
          then: schema => schema.min(1, t('opening_time_min')).max(30, t('opening_time_max')).required(t('opening_time_required'))
        }),
        relay: Yup.string(),
        type: Yup.string().when('active', {
          is: true,
          otherwise: schema => schema.notRequired(),
          then: schema => schema.oneOf(['S', 'ES', 'E'], t('door_type_required')).required(t('door_type_required'))
        })
      })
    ),
    name: Yup.string().required(t('name_required'))
  });
  const [bridge, setBridge] = useState({
    antiPassback: false,
    bridge_id: '',
    doors: DEFAULT_DOORS,
    name: ''
  });

  const [isAntipassbackAllowed, setIsAntipassbackAllowed] = useState(false);

  const checkAntipassbackState = doors => {
    const hasEntrada = doors.some(door => door.active && (door.type === 'E' || door.type === 'ES'));
    const hasSalida = doors.some(door => door.active && (door.type === 'S' || door.type === 'ES'));
    return hasEntrada && hasSalida;
  };

  useEffect(() => {
    if (data) {
      console.log('data:', data);

      //Se asegura que siempre haya 4 puertas, completando con `DEFAULT_DOORS`
      const filledDoors = [...data.doors];

      while (filledDoors.length < 4) {
        filledDoors.push({ active: false, gateId: '', name: '', openingTime: '', relay: '', type: '' });
      }

      setBridge({
        antiPassback: data.antiPassback || false,
        bridge_id: data.bridge_id || '',
        doors: filledDoors,
        name: data.name || ''
      });

      setIsAntipassbackAllowed(checkAntipassbackState(filledDoors));
    }
  }, [data]);

  return (
    <Modal onClose={handleClose} open={open}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          height: '60vh',
          left: '50%',
          maxWidth: '750px',
          overflowY: 'auto',
          p: 4,
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%'
        }}
      >
        <Typography sx={{ fontWeight: 'bold', mb: 2 }} variant="h6">
          {t('modal_title')}
        </Typography>

        <Formik
          enableReinitialize
          initialValues={bridge}
          onSubmit={values => {
            //Si antipassback no es permitido, se asegura que el valor enviado sea `false`
            if (!isAntipassbackAllowed) {
              values.antiPassback = false;
            }
            handleSave(values);
            handleClose();
          }}
          validationSchema={validationSchema}
        >
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form>
              <Box mb={2}>
                <Typography fontWeight="bold">{t('name')}</Typography>
                <Field
                  as={TextField}
                  error={touched.name && !!errors.name}
                  fullWidth
                  helperText={touched.name && errors.name}
                  name="name"
                  size="small"
                  variant="outlined"
                />
              </Box>

              <Box p={2} sx={{ backgroundColor: '#f5f5f5', borderRadius: 2 }}>
                <Typography fontWeight="bold">{t('doors')}</Typography>
                {values.doors.map((door, index) => {
                  const isDisabled = !values.doors[index].active;

                  return (
                    <Grid alignItems="center" container key={index} mb={2} spacing={1}>
                      <Grid item xs={1}>
                        <Checkbox
                          checked={values.doors[index].active}
                          onChange={e => {
                            const newActiveState = e.target.checked;
                            setFieldValue(`doors[${index}].active`, newActiveState);

                            if (!newActiveState) {
                              setErrors(prevErrors => {
                                const newErrors = { ...prevErrors };
                                delete newErrors.doors?.[index]?.name;
                                delete newErrors.doors?.[index]?.openingTime;
                                delete newErrors.doors?.[index]?.type;
                                return newErrors;
                              });
                            }

                            const updatedDoors = values.doors.map((d, i) => (i === index ? { ...d, active: newActiveState } : d));
                            setIsAntipassbackAllowed(checkAntipassbackState(updatedDoors));
                          }}
                        />
                      </Grid>

                      <Grid item xs={1}>
                        <Typography fontWeight="bold">{index + 1}</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          disabled={isDisabled}
                          error={touched.doors?.[index]?.name && !!errors.doors?.[index]?.name}
                          fullWidth
                          helperText={touched.doors?.[index]?.name && errors.doors?.[index]?.name}
                          label={t('door_name')}
                          name={`doors[${index}].name`}
                          size="small"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <Field
                          as={TextField}
                          disabled={isDisabled}
                          error={touched.doors?.[index]?.openingTime && !!errors.doors?.[index]?.openingTime}
                          fullWidth
                          helperText={touched.doors?.[index]?.openingTime && errors.doors?.[index]?.openingTime}
                          label={t('door_opening_time')}
                          name={`doors[${index}].openingTime`}
                          size="small"
                          type="number"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormControl disabled={isDisabled} fullWidth size="small" variant="outlined">
                          <InputLabel id={`door-type-label-${index}`} shrink sx={{ backgroundColor: 'white', px: 1 }}>
                            {t('door_type')}
                          </InputLabel>
                          <Field
                            as={Select}
                            displayEmpty
                            label={t('door_type')}
                            labelId={`door-type-label-${index}`}
                            margin="dense"
                            name={`doors[${index}].type`}
                            onChange={e => {
                              setFieldValue(`doors[${index}].type`, e.target.value);

                              const updatedDoors = values.doors.map((d, i) => (i === index ? { ...d, type: e.target.value } : d));
                              setIsAntipassbackAllowed(checkAntipassbackState(updatedDoors));
                            }}
                            value={values.doors[index].type}
                          >
                            <MenuItem disabled value="">
                              {t('select_type')}
                            </MenuItem>
                            <MenuItem title={t('exitInfo')} value="S">
                              {t('exit')}
                            </MenuItem>
                            <MenuItem title={t('entranceInfo')} value="E">
                              {t('entrance')}
                            </MenuItem>
                            <MenuItem title={t('entrance_exitInfo')} value="ES">
                              {t('entrance_exit')}
                            </MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.antiPassback}
                    disabled={!isAntipassbackAllowed}
                    onChange={e => setFieldValue('antiPassback', e.target.checked ? true : false)}
                  />
                }
                label="Antipassback"
                sx={{ mt: 2 }}
              />

              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button color="secondary" onClick={handleClose} sx={{ color: 'orange', textTransform: 'none' }}>
                  {t('close')}
                </Button>
                <Button color="primary" type="submit" variant="contained">
                  {t('save')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default BridgeEditModal;
