import React from 'react';
import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const UserStatusView = ({ data, handleClose }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const { details } = data || {};
  const status = details?.[0]?.status;

  return (
    <Stack gap={2}>
      <Typography color="textSecondary" variant="body2">
        {t('warnings.userStatus')}
      </Typography>
      <FormControlLabel control={<Checkbox checked={!!status} color="primary" disabled name="status" />} label={t('status.label')} />

      <Button color="primary" onClick={handleClose} sx={{ mt: 2 }} variant="contained">
        {t('close')}
      </Button>
    </Stack>
  );
};

export default UserStatusView;
