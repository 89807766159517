import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DefaultLayout from 'src/components/layouts/DefaultLayout';
import Bridges from 'src/components/PropertyManager/Bridges/index';
import PointsAccess from 'src/components/PropertyManager/PointsAccess/index';

function PageAccessPoints() {
  const { t } = useTranslation('admin');
  const [selectedTab, setSelectedTab] = useState('accessPoints');
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <DefaultLayout title={t('accessPoints.title')}>
      <Tabs aria-label="Configuración tabs" indicatorColor="secondary" onChange={handleTabChange} textColor="secondary" value={selectedTab}>
        <Tab label={t('accessPoints.subtitleOne')} value="accessPoints" />
        <Tab label={t('accessPoints.subtitleTwo')} value="bridges" />
      </Tabs>

      <Box sx={{ mt: 3, padding: 0 }}>
        {/* Contenido de las pestañas */}
        {selectedTab === 'accessPoints' && <PointsAccess />}
        {selectedTab === 'bridges' && <Bridges />}
      </Box>
    </DefaultLayout>
  );
}

export default PageAccessPoints;
