import { useContext, useEffect, useState } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthorizationContext } from 'src/context/AuthorizationProvider';
import useCRUD from 'src/hooks/useCRUD';

import AccessPointEditModal from './Edit';
import AccessPointsList from './List';
import AccessPointViewModal from './View';

const AccessPointsCRUD = () => {
  const { customerId } = useContext(AuthorizationContext);
  const { data, saveData } = useCRUD(`${process.env.REACT_APP_API_URL}/property/${customerId}/access-points`);
  const [accessPoints, setAccessPoints] = useState(data?.accessPoints || []);
  const [bridges, setBridges] = useState(data?.bridgesCustomer || []);
  const [query, setQuery] = useState('');
  const [filteredAccessPoints, setFilteredAccessPoints] = useState(accessPoints);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);

  const { t } = useTranslation('admin', { keyPrefix: 'accessPoints' });

  useEffect(() => {
    if (data) {
      setAccessPoints(data.accessPoints || []);
      setBridges(data.bridgesCustomer || []);
      setFilteredAccessPoints(data.accessPoints || []);
    }
  }, [data]);

  const handleEditModalOpen = pointId => {
    const point = accessPoints.find(ap => ap.accessPointId === pointId);
    if (point) {
      const assignedBridges = bridges.filter(bridge => bridge.access_point_id === point.accessPointId);
      setSelectedPoint({ ...point, bridgesAssigned: assignedBridges });
    } else {
      setSelectedPoint(null);
    }
    setEditModalOpen(true);
  };

  const handleViewModalOpen = pointId => {
    const point = accessPoints.find(ap => ap.accessPointId === pointId);
    if (point) {
      const assignedBridges = bridges.filter(bridge => bridge.access_point_id === point.accessPointId);
      setSelectedPoint({ ...point, bridges: assignedBridges });
    }
    setViewModalOpen(true);
  };

  const handleModalClose = setModalOpen => {
    setSelectedPoint(null);
    setModalOpen(false);
  };

  const handleEditModalClose = () => handleModalClose(setEditModalOpen);
  const handleViewModalClose = () => handleModalClose(setViewModalOpen);

  const handleSubmit = async accessPoint => {
    console.log('accessPoint', accessPoint);
    const isUpdate = !!accessPoint.accessPointId;
    try {
      const method = isUpdate ? 'PATCH' : 'POST';
      const url = isUpdate
        ? `${process.env.REACT_APP_API_URL}/property/${customerId}/access-points/${accessPoint.accessPointId}`
        : `${process.env.REACT_APP_API_URL}/property/${customerId}/access-points`;

      const body = {
        bridgeIds: accessPoint.bridgesAssigned.map(bridge => bridge.bridge_id),
        description: accessPoint.description,
        name: accessPoint.name
      };

      await saveData(url, method, body);
      toast.success(isUpdate ? t('messages.updateSuccess') : t('messages.createSuccess'));
    } catch (error) {
      console.error('Error:', error);
      toast.error(isUpdate ? t('messages.updateError') : t('messages.createError'));
    }
  };

  const handleSearch = e => {
    const value = e.target.value.toLowerCase();
    setQuery(value);

    setFilteredAccessPoints(
      value ? accessPoints.filter(point => point.name.toLowerCase().includes(value) || point.location?.toLowerCase().includes(value)) : accessPoints
    );
  };

  return (
    <>
      <Stack>
        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <Button color="primary" onClick={() => handleEditModalOpen()} variant="contained">
            {t('addAccessPoint')}
          </Button>
          <TextField
            onChange={handleSearch}
            placeholder={t('search')}
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px'
              },
              maxWidth: '300px'
            }}
            value={query}
            variant="outlined"
          />
        </Box>
        <AccessPointsList
          accessPoints={filteredAccessPoints}
          bridges={bridges}
          handleEditModalOpen={handleEditModalOpen}
          handleViewModalOpen={handleViewModalOpen}
        />
      </Stack>
      <AccessPointEditModal action={handleSubmit} bridges={bridges} data={selectedPoint} handleClose={handleEditModalClose} open={editModalOpen} />
      <AccessPointViewModal data={selectedPoint} handleClose={handleViewModalClose} open={viewModalOpen} />
    </>
  );
};

export default AccessPointsCRUD;
