import { Button, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const UserSubscriptionsEdit = ({ action, data, handleClose }) => {
  const { t } = useTranslation('admin', { keyPrefix: 'property.users' });

  const { email = '', firstName = '', lastName = '', userId } = data || {};

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email(t('errors.invalidEmail')).required(t('errors.required')),
    firstName: Yup.string().trim().required(t('errors.required')),
    lastName: Yup.string().trim().required(t('errors.required'))
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email, firstName, lastName },
    validateOnChange: true,
    validationSchema: validationSchema
  });

  const handleModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  const handleModalSubmit = async () => {
    await action({ ...formik.values, userId });
    handleModalClose();
  };

  const enabled = Object.keys(formik.touched).length && formik.isValid;

  return (
    <form>
      <Stack gap={2}>
        <Stack direction="row" gap={2}>
          <TextField
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            fullWidth
            helperText={formik.touched.firstName && formik.errors.firstName}
            label={t('fields.firstName')}
            name="firstName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.firstName}
          />
          <TextField
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            fullWidth
            helperText={formik.touched.lastName && formik.errors.lastName}
            label={t('fields.lastName')}
            name="lastName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            required
            value={formik.values.lastName}
          />
        </Stack>
        <TextField
          disabled={!!data?.email}
          error={formik.touched.email && Boolean(formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label={t('fields.email')}
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          required
          value={formik.values.email}
        />
        <Button color="primary" disabled={!enabled} onClick={handleModalSubmit} sx={{ mt: 2 }} variant="contained">
          {t(data ? 'save' : 'invite')}
        </Button>
      </Stack>
    </form>
  );
};

export default UserSubscriptionsEdit;
